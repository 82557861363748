





































































































import Vue from 'vue';
import { formatDateTime, millisToTimeString } from '@/utils/tools';
import { mapGetters } from 'vuex';
import SessionWrapper from '@/components/Viewer/SessionWrapper.vue';
import ThemeModel from '@/dtos/theme';
import LangSwitch from '@/components/LangSwitch.vue';

export default Vue.extend({
  components: { SessionWrapper, LangSwitch },
  data() {
    return {
      now: new Date(),
    };
  },
  created() {
    // Update current time every 10s
    setInterval(() => {
      this.now = new Date();
    }, 10000);
  },
  computed: {
    ...mapGetters({
      activeSession: 'viewer/getActiveSession',
      sessionTheme: 'viewer/getSessionTheme',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    elapsedTime(): string {
      if (this.activeSession) {
        const elapsedTime =
          this.now.getTime() - this.activeSession.startAt.toDate().getTime();
        return millisToTimeString(elapsedTime);
      }
      return '-';
    },
    remainingTime(): string {
      if (this.activeSession) {
        const remainingTime =
          this.activeSession.endAt.toDate().getTime() - this.now.getTime();
        return millisToTimeString(remainingTime);
      }
      return '-';
    },
  },
  methods: {
    formatDateTime(input: any): string {
      return formatDateTime(input);
    },
  },
});
